<!-- @format -->

<script>
  import {abbr} from './helpers'

  export let pubkey
  export let link = false
  export let alias = null
  export let color = null

  if (alias !== null) {
    window.aliasPromises[pubkey] = Promise.resolve({alias, color})
  }

  var el
  var aliasPromise = Promise.resolve({alias: pubkey})

  $: if (pubkey !== '') {
    if (pubkey in window.aliasPromises) {
      aliasPromise = window.aliasPromises[pubkey]
    } else {
      aliasPromise = new Promise(async (resolve, reject) => {
        let res = await (
          await fetch(
            `/api/nodealiases?pubkey=eq.${pubkey}&select=alias,color&order=first_seen.desc`,
            {
              headers: {
                'Range-Unit': 'items',
                Range: '0-1'
              }
            }
          )
        ).json()

        if (res.length === 0) {
          reject('not found, someone please catch this')
          return
        }

        if (res.length && res[0].alias != '') {
          resolve(res[0])
          return
        }

        reject('should never happen')
      })

      window.aliasPromises[pubkey] = aliasPromise
    }
  }

  $: namePromise = aliasPromise
    .then(a => a.alias)
    .then(name => (name !== '' ? name : abbr(pubkey)))
    .catch(() => abbr(pubkey))

  var hasColor = false
  $: aliasPromise.then(a => {
    if (a.color) {
      el.style.setProperty('--nodecolor', `#${a.color}`)
      hasColor = true
    }
  })
</script>

<style>
  .colored::after {
    content: ' ';
    transition: color 300ms linear;
    border-radius: 85px;
    display: inline-block;
    margin-left: 4px;
    height: 9px;
    width: 9px;
    position: relative;
    top: 1px;
    background: var(--nodecolor);
  }
</style>

{#if link}
<a href="/node/{pubkey}" class:colored="{hasColor}" bind:this="{el}"
  >{#await namePromise}{pubkey}{:then name}{name}{:catch
  error}{abbr(pubkey)}{/await}</a
>
{:else}
<span class:colored="{hasColor}" bind:this="{el}"
  >{#await namePromise}{pubkey}{:then name}{name}{:catch
  error}{abbr(pubkey)}{/await}</span
>
{/if}
