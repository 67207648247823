<!-- @format -->

<script>
  const endpoint = 'https://' + location.host + '/api'
</script>

<style>
  article {
    width: 90%;
    max-width: 900px;
    margin: 10px auto;
    border-radius: 15px;
    padding: 1px 10px 2px;
    background-color: rgb(174, 232, 175, 0.5);
  }
  #methods div {
    margin-bottom: 20px;
  }
  #methods ul {
    list-style: none;
    padding: none;
  }
  h4 {
    font-family: monospace;
    font-weight: bold;
  }
  ul .code {
    margin-top: 7px;
  }
  .code {
    font-family: monospace;
    background-color: #eae6d3;
    padding: 7px 6px 1px;
    border-radius: 3px;
  }
  code.code {
    display: inline-block;
  }
</style>

<svelte:head>
  <title>API Docs - ln.bigsun.xyz</title>
  <meta
    name="description"
    content="PostgREST-based API with a ton of information about Lightning public channels and nodes."
  />
</svelte:head>

<h1>About lnchannels and documentation</h1>

<article>
  <h2>Frequently asked questions</h2>
  <p>
    <b
      >Why is the number of open channels here much bigger than other explorers
      show?</b
    >
    Because we keep counting the channels as open until their funding
    transaction is spent on the chain. Apparently many channels are abandoned
    and nodes turned off, sometimes temporarily, and the channels remain
    technically open, but cease to be announced to the rest of the network.
    Other explorers remove these inactive channels from their count of open
    channels, but we don't.
  </p>
  <p>
    <b>Why are you missing channel xyz?</b> Because we only gather the list of
    announced channels once per day, on average, so if a channel was opened and
    closed before we had a chance to see it we will never know it existed.
  </p>
</article>

<article>
  <h2>About this website</h2>

  <ul>
    <li>It is ran by <a href="https://twitter.com/fiatjaf">@fiatjaf</a>.</li>
    <li>
      It is based on data collected by a <i>Python</i>
      <a
        href="https://github.com/fiatjaf/lnchannels/blob/master/getdata/__main__.py"
        >script</a
      >
      that uses
      <a href="https://github.com/ElementsProject/lightning/">c-lightning</a>,
      <a href="https://github.com/fiatjaf/sparko">sparko</a>,
      <a href="http://bitcoinknots.org/">Bitcoin Knots</a> and
      <a href="https://github.com/Blockstream/esplora">Esplora</a>.
    </li>
    <li>
      All data is stored on a
      <a href="https://www.postgresql.org/">Postgres</a> database and served raw
      by <a href="http://postgrest.org/en/v7.0.0/">PostgREST</a>.
    </li>
    <li>
      The frontend is a static site built with
      <a href="https://svelte.dev/">Svelte</a>.
    </li>
  </ul>
</article>

<article>
  <h2>Run your own instance of this site</h2>
  <p>
    Get the source code and follow the instructions at
    <a href="https://github.com/fiatjaf/lnchannels"
      >https://github.com/fiatjaf/lnchannels</a
    >.
  </p>
</article>

<article>
  <h2>HTTP API</h2>
  <ul>
    <li>The base endpoint is <code class="code">{endpoint}</code>.</li>
    <li>
      This API is powered by
      <a href="https//postgrest.org/en/v6.0/api.html#custom-queries"
        >PostgREST</a
      >, so you can go there if you want to craft a very complex query.
    </li>
    <li>All API methods return a JSON array.</li>
    <li>
      There's a limit of 1000 rows per API call, please let me know if you need
      more.
    </li>
    <li>
      Every endpoint supports
      <a href="https//postgrest.org/en/v6.0/api.html#v-filter"
        >returning only specified fields</a
      >, filtering by attributes using
      <a href="https//postgrest.org/en/v6.0/api.html#operators"
        >eq, gt, lt etc.</a
      >
      operators,
      <a href="https//postgrest.org/en/v6.0/api.html#limits-and-pagination"
        >pagination</a
      >
      and other
      <a href="https//postgrest.org/en/v6.0/api.html#renaming-columns"
        >fancy things</a
      >.
    </li>
    <li>
      <a href="https://postgrest.org/en/v6.0/api.html#custom-queries"
        >RPC endpoints</a
      >
      allow you to pass the argument either as a query string in a GET request
      or as a JSON body in a POST request.
    </li>
    <li>The data is updated once per day.</li>
    <li>
      There's a <a href="https://www.postman.com/">Postman</a> collection
      <a href="https://www.postman.com/collections/5f35972c0e0f8b3cdc4e"
        >available here</a
      >
    </li>
    .
  </ul>
  <div id="methods">
    <h3>Methods</h3>
    <div>
      <h4>/channels</h4>
      <p>
        Returns an array of plain channels. That includes close data and closure
        type if the channel was closed already, otherwise these values will be
        null.
      </p>
      <pre class="code">
{`[
  {
    "short_channel_id": "548804x1008x1",
    "nodes": [
      "02b0172bb38617fa3afdb69664468b492d5a21062a4fa8b47284b0cc320e6e7420",
      "03a503d8e30f2ff407096d235b5db63b4fcf3f89a653acb6f43d3fc492a7674019"
    ],
    "a": 1,
    "b": 0,
    "txs": {
      "a": [
        "335e31061845abb14f38476d1e0cfb594bda83432dc23ea3036ffc3684b550a9",
        "7e47fae4d5c70ebc9ea1b697ee46577b4887400674b7692dc6d2e22560b52903"
      ],
      "b": [
        "e65c9e3f1c9e9d6dbd7fec573748b74910bf7d571ed5861de314a7332d74d0f6"
      ],
      "funding": [
        "ab5f85d8f482cedb0b3f58d999f1d53a98436f01f8e899d58e4e9c3330a8f422",
        "a333c58d3a1c943435fdffdb136647cf8fd0050a4a2f9de096fe8a6d226fcdca"
      ]
    },
    "open": {
      "fee": 154,
      "time": 1541379923,
      "txid": "e0367a661ce701e13d13720176f452d885c5fc40d4c323fa9d96a8aafa199453",
      "block": 548804,
      "address": "bc1qd6hcfq7ky6de5p6ng5v28gpkd4pfxc6jllp7hhca0yjhsz6xt4ws22e5jl"
    },
    "close": {
      "fee": 6722,
      "time": 1567176161,
      "txid": "bd5e90eb5580abb366b2ef0add5132508b6ae7b1f632de01681a06e66bf34d3a",
      "type": "force",
      "block": 592447,
      "htlcs": {
        "a": [
          {
            "kind": "timeout",
            "amount": 258151
          }
        ],
        "b": []
      },
      "balance": {
        "a": 146883,
        "b": 388244
      }
    },
    "closer": "b",
    "funder": 0
    "satoshis": 800000,
    "last_update": "2019-09-15T03:25:38"
  },
  ...
]`}
  </pre
      >
      <b>Examples:</b>
      <ul>
        <li>
          <code class="code"
            >curl '{endpoint}/channels?short_channel_id=eq.572699x1147x0'</code
          >
          returns only the channel 572699x1147x0.
        </li>
        <li>
          <code class="code"
            >curl
            '{endpoint}/channels?open&gt;&gt;block=gte.622000&amp;open-&gt;&gt;block=lt.623000'</code
          >
          returns only channels created between blocks 622000 and 622999.
        </li>
        <li>
          <code class="code"
            >curl '{endpoint}/channels?close&gt;&gt;type=eq.penalty'</code
          >
          returns only channels closed with penalty transactions.
        </li>
      </ul>
    </div>
    <div>
      <h4>/nodes</h4>
      <p>
        Returns an array of nodes. That includes channel counts, capacity and
        other aggregated values.
      </p>
      <pre class="code">
{`[
  {
    "pubkey": "024bd94f0425590434538fd21d4e58982f7e9cfd8f339205a73deb9c0e0341f5bd",
    "alias": "CL.rompert.com🔵 ",
    "oldestchannel": 511949,
    "openchannels": 32,
    "closedchannels": 9,
    "capacity": 27790149,
    "avg_duration": 68863.51219512195,
    "avg_open_fee": 3391.8048780487807,
    "avg_close_fee": 18149.777777777777
  },
  ...
]`}
  </pre
      >
      <b>Examples:</b>
      <ul>
        <li>
          <code class="code"
            >curl
            '{endpoint}/nodes?pubkey=eq.024bd94f0425590434538fd21d4e58982f7e9cfd8f339205a73deb9c0e0341f5bd'</code
          >
          returns only the node
          024bd94f0425590434538fd21d4e58982f7e9cfd8f339205a73deb9c0e0341f5bd.
        </li>
        <li>
          <code class="code"
            >curl '{endpoint}/nodes?order=capacity.desc' -H 'Range: 0-4'</code
          >
          returns the top 5 nodes by capacity.
        </li>
      </ul>
    </div>
    <div>
      <h4>/nodealiases</h4>
      <p>
        Returns an array of aliases associated with a node. These mean the node
        was spotted using that alias at some point, during some interval of
        time, before it then changed its alias.
      </p>
      <pre class="code">
{`[
  {
    "pubkey": "036b48ae27f45bc497eb92b6a43da534f4d771b896e74d787db171200324690fca",
    "alias": "036b48ae27f45bc497eb",
    "color": "036b48a",
    "first_seen": "2019-07-31T19:13:38"
  },
  ...
]`}
  </pre
      >
      <b>Examples:</b>
      <ul>
        <li>
          <code class="code"
            >curl
            '{endpoint}/nodealiases?pubkey=eq.024bd94f0425590434538fd21d4e58982f7e9cfd8f339205a73deb9c0e0341f5bd&amp;order=first_seen.asc'</code
          >
          returns the history of aliases for the specified node, sorted.
        </li>
      </ul>
    </div>
    <div>
      <h4>/policies</h4>
      <p>
        Returns an array of fee policies associated with a channel and a
        direction. Direction "0" means it's from "node1" to "node0" and
        direction "1" means from "node0" to "node1".
      </p>
      <pre class="code">
{`[
  {
    "short_channel_id": "513675x2245x0",
    "direction": 1,
    "base_fee_millisatoshi": 1000,
    "fee_per_millionth": 10,
    "delay": 14,
    "update_time": "2019-03-18T04:23:01"
  },
  ...
]`}
  </pre
      >
      <b>Examples:</b>
      <ul>
        <li>
          <code class="code"
            >curl
            '{endpoint}/policies?short_channel_id=eq.513675x2245x0&amp;direction=eq.1&amp;order=update_time.asc'</code
          >
          returns the history of policy changes for the channel 513675x2245x0,
          direction 1.
        </li>
      </ul>
    </div>
    <div>
      <h4>/rpc/node_channels</h4>
      <p>
        Returns an array of channels with data from the point-of-view of the
        node specified so they have more data about the channel peer and the
        concept of "outgoing" and "incoming" fee.
      </p>
      <pre class="code">
{`[
  {
    "short_channel_id": "619237x1477x1",
    "peer_id": "025033fdfd6f4e0e909776f0e8250c997f75de9cb11466e3a36fb8e6c7a1abcbb9",
    "peer_name": "remoteadmin.io",
    "peer_size": 58716565,
    "open_block": 619237,
    "open_fee": 3228,
    "close_block": null,
    "close_fee": null,
    "satoshis": 36772,
    "outgoing_fee_per_millionth": 1,
    "outgoing_base_fee_millisatoshi": 1000,
    "outgoing_delay": 40,
    "incoming_base_fee_millisatoshi": 1000,
    "incoming_fee_per_millionth": 1,
    "incoming_delay": 40,
    "close_type": null,
    "close_htlc_count": null
  },
  ...
]`}
  </pre
      >
    </div>
    <div>
      <h4>/rpc/search</h4>
      <p>
        Returns an array of search results that can specify either nodes or
        channels. The fields searched are the node pubkey, transactions (open
        and close), channel address, all the history of node aliases and the
        channel short_channel_id (prefix search works in this case).
      </p>
      <pre class="code">
{`[
  {
    "url": "/node/021779d56f6992431e959991a664d678a2f41054073adb48c5a351d791c9cb0781",
    "kind": "node",
    "label": "Banana!🍌 (24 channels)",
    "closed": false
  },
  {
    "url": "/node/022fc6fb0c0b1ccbcf8ff360b9e6aeb1c78f52ab7660e84996a5227d0a515ae6fb",
    "kind": "node",
    "label": "Electric-Banana (1 channels)",
    "closed": false
  },
  {
    "url": "/node/02baf43d3e283ebf113dc56a49ba2ad8d8ff41793594991273c6adb68820ac70eb",
    "kind": "node",
    "label": "TheMadBanana (1 channels)",
    "closed": false
  },
  {
    "url": "/node/035c77dc0a10fe60e1304ae5b57d8fef87751add5d016b896d854fb706be6fc96c",
    "kind": "node",
    "label": "Banana Phone! (1 channels)",
    "closed": false
  }
]`}
  </pre
      >
    </div>
  </div>
</article>
