<!-- @format -->

<script>
  import {onMount} from 'svelte'

  export let channels

  var el
  var chart

  onMount(() => {
    var bubbledata = []

    for (let i = channels.length - 1; i >= 0; i--) {
      let chan = channels[i]

      if (!chan.close.block) {
        // if it's open add to bubble chart
        bubbledata.push({
          x: chan.open.block,
          y: chan.satoshis,
          z: chan.peer.size,
          name: chan.peer.name,
          color: '#' + chan.peer.color,
          scid: chan.short_channel_id
        })
      }
    }

    console.log(bubbledata)

    // channel bubbles
    chart = H.chart(el, {
      colors: [],
      title: {text: ''},
      yAxis: [{title: {text: 'channel size (sat)', enabled: null}, floor: 0}],
      series: [
        {
          type: 'bubble',
          data: bubbledata,
          showInLegend: false,
          minSize: '1%',
          maxSize: '30%',
          sizeBy: 'width',
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              color: 'black',
              textOutline: 'none',
              fontWeight: 'normal'
            }
          },
          tooltip: {
            headerFormat: '',
            followPointer: true,
            followTouchMove: true,
            pointFormat: '{point.name}: {point.y}',
            valueSuffix: ' sat'
          },
          events: {
            click: e => {
              document.getElementById(`ch-${e.point.scid}`).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              })
            }
          }
        }
      ]
    })

    return () => chart.destroy()
  })
</script>

<div>
  <h4>current channels</h4>
  <div bind:this="{el}" />
</div>
