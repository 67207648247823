<!-- @format -->

<style>
  svg {
    display: block;
    margin: 44px auto;
  }
</style>

<div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin:auto;background:#fff;display:block;"
    width="300px"
    height="300px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g
      transform="translate(50 50) scale(0.47000000000000003) translate(-50 -50)"
    >
      <g>
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          calcMode="spline"
          dur="5s"
          values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50"
          keyTimes="0;0.25;0.5;0.75;1"
          keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
        ></animateTransform>
        <g>
          <animateTransform
            attributeName="transform"
            type="scale"
            dur="1.25s"
            repeatCount="indefinite"
            calcMode="spline"
            values="1;1;0.5"
            keyTimes="0;0.5;1"
            keySplines="1 0 0 1;1 0 0 1"
          ></animateTransform>
          <g transform="translate(25 25)">
            <rect x="-25" y="-25" width="52" height="52" fill="var(--orange)">
              <animate
                attributeName="fill"
                dur="5s"
                repeatCount="indefinite"
                calcMode="spline"
                values="var(--orange);var(--green);var(--gold);var(--blue);var(--red)"
                keyTimes="0;0.25;0.5;0.75;1"
                keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
              ></animate>
            </rect>
          </g>
          <g transform="translate(25 75)">
            <rect x="-25" y="-25" width="52" height="50" fill="var(--orange)">
              <animateTransform
                attributeName="transform"
                type="scale"
                dur="1.25s"
                repeatCount="indefinite"
                calcMode="spline"
                values="0;1;1"
                keyTimes="0;0.5;1"
                keySplines="1 0 0 1;1 0 0 1"
              ></animateTransform>
              <animate
                attributeName="fill"
                dur="5s"
                repeatCount="indefinite"
                calcMode="spline"
                values="var(--orange);var(--green);var(--gold);var(--blue);var(--red)"
                keyTimes="0;0.25;0.5;0.75;1"
                keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
              ></animate>
            </rect>
          </g>
          <g transform="translate(75 25)">
            <rect x="-25" y="-25" width="50" height="52" fill="var(--orange)">
              <animateTransform
                attributeName="transform"
                type="scale"
                dur="1.25s"
                repeatCount="indefinite"
                calcMode="spline"
                values="0;1;1"
                keyTimes="0;0.5;1"
                keySplines="1 0 0 1;1 0 0 1"
              ></animateTransform>
              <animate
                attributeName="fill"
                dur="5s"
                repeatCount="indefinite"
                calcMode="spline"
                values="var(--orange);var(--green);var(--gold);var(--blue);var(--red)"
                keyTimes="0;0.25;0.5;0.75;1"
                keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
              ></animate>
            </rect>
          </g>
          <g transform="translate(75 75)">
            <rect x="-25" y="-25" width="50" height="50" fill="var(--orange)">
              <animateTransform
                attributeName="transform"
                type="scale"
                dur="1.25s"
                repeatCount="indefinite"
                calcMode="spline"
                values="0;1;1"
                keyTimes="0;0.5;1"
                keySplines="1 0 0 1;1 0 0 1"
              ></animateTransform>
              <animate
                attributeName="fill"
                dur="5s"
                repeatCount="indefinite"
                calcMode="spline"
                values="var(--orange);var(--green);var(--gold);var(--blue);var(--red)"
                keyTimes="0;0.25;0.5;0.75;1"
                keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
              ></animate>
            </rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
