<!-- @format -->

<script>
  import {onMount, getContext} from 'svelte'
  import {plotOptions} from '../helpers'

  const first_block = getContext('first_block')

  var el
  var chart

  var blocks = []

  var chartType = 'counts' // or 'sizes'
  var stacking = 'percent' // or 'normal' or null

  const seriesColors = [
    ['unknown', '#e4dfda'],
    ['mutual_unused', '#4cc9ba'],
    ['mutual', '#46af5d'],
    ['force_inflight', '#bc703a'],
    ['force', '#db9d4c'],
    ['force_unused', '#bf7959'],
    ['penalty', '#d12323']
  ]

  var chartAggregates = {
    counts: {},
    sizes: {}
  }

  for (let i = 0; i < seriesColors.length; i++) {
    let [serie, _] = seriesColors[i]
    chartAggregates.counts[serie] = []
    chartAggregates.sizes[serie] = []
  }

  function toggleType(e) {
    e.preventDefault()
    chartType = chartType === 'counts' ? 'sizes' : 'counts'
    buildChart()
  }

  function toggleStacking(e) {
    e.preventDefault()
    stacking =
      stacking === 'percent'
        ? 'normal'
        : stacking === 'normal'
        ? undefined
        : 'percent'
    buildChart()
  }

  function buildChart() {
    if (chart) chart.destroy()

    chart = H.chart(el, {
      title: {text: ''},
      chart: {type: 'areaspline'},
      xAxis: {
        categories: blocks.map(b => b.toString().slice(0, -2) + '__')
      },
      yAxis: [
        {visible: false},
        {visible: false},
        {visible: false},
        {visible: false},
        {visible: false}
      ],
      series: seriesColors.map(([name, color]) => ({
        name,
        data: chartAggregates[chartType][name],
        color
      })),
      plotOptions: {...plotOptions, areaspline: {stacking: stacking}}
    })
  }

  onMount(async () => {
    let data = await (
      await fetch(`/api/closetypes?blockgroup=gt.${first_block}`, {
        headers: {
          'Range-Unit': 'items',
          Range: '1-'
        }
      })
    ).json()

    for (let i = 0; i < data.length; i++) {
      let ct = data[i]
      blocks.push(ct.blockgroup)
      for (let i = 0; i < seriesColors.length; i++) {
        let [serie, _] = seriesColors[i]
        chartAggregates.counts[serie].push(ct[serie].c)
        chartAggregates.sizes[serie].push(ct[serie].s)
      }
    }

    buildChart()

    return () => {
      chart.destroy()
    }
  })
</script>

<div>
  <h4>
    types of channel closes (by
    <span class="toggle" on:click="{toggleType}">{chartType}</span>) (<span
      class="toggle"
      on:click="{toggleStacking}"
      >{#if stacking}{stacking}{:else}not stacked{/if}</span
    >)
  </h4>
  <div bind:this="{el}" />
</div>
