<!-- @format -->

<script>
  import {onMount, getContext} from 'svelte'
  import {plotOptions} from '../helpers'

  const first_block = getContext('first_block')

  var el
  var chart

  onMount(async () => {
    let data = await (
      await fetch(`/api/rpc/home_chart?since_block=${first_block}`)
    ).json()

    var blocks = []
    var openings = []
    var closings = []
    var total = []
    var capacity = []
    var fee_total = []
    var outstanding_htlcs = []

    for (let i = 0; i < data.length; i++) {
      let {blockgroup, opened, closed, cap_change, fee, htlcs} = data[i]
      blocks.push(blockgroup)
      openings.push(opened)
      closings.push(closed)
      total.push(
        (total.length > 0 ? total[total.length - 1] : 0) + opened - closed
      )
      capacity.push(
        (capacity.length > 0 ? capacity[capacity.length - 1] : 0) +
          cap_change / 100000000
      )
      fee_total.push(fee)
      outstanding_htlcs.push(htlcs)
    }
    blocks = blocks.slice(1)
    openings = openings.slice(1)
    closings = closings.slice(1)
    total = total.slice(1)
    capacity = capacity.slice(1)
    fee_total = fee_total.slice(1)
    outstanding_htlcs = outstanding_htlcs.slice(1)

    chart = H.chart(el, {
      title: {text: ''},
      xAxis: {
        categories: blocks.map(b => b.toString().slice(0, -2) + '__')
      },
      yAxis: [
        {visible: false},
        {visible: false},
        {visible: false},
        {visible: false},
        {visible: false}
      ],
      series: [
        {
          name: 'capacity (btc)',
          type: 'area',
          data: capacity,
          step: 'left',
          yAxis: 2,
          color: 'var(--gold)'
        },
        {
          name: 'total',
          type: 'area',
          data: total,
          step: 'left',
          yAxis: 1,
          color: 'var(--blue)'
        },
        {
          name: 'openings',
          type: 'column',
          data: openings,
          yAxis: 0,
          color: 'var(--green)',
          borderWidth: 1
        },
        {
          name: 'closings',
          type: 'column',
          data: closings,
          yAxis: 0,
          color: 'var(--red)',
          borderWidth: 1
        },
        {
          name: 'onchain fee total',
          type: 'spline',
          data: fee_total,
          yAxis: 3,
          color: 'rgba(0, 0, 0, 0.5)',
          lineWidth: 1,
          visible: Math.random() < 0.08
        },
        {
          name: 'outstanding_htlcs',
          type: 'spline',
          data: outstanding_htlcs,
          yAxis: 4,
          dashStyle: 'Dash',
          color: '#c1a478',
          lineWidth: 1,
          visible: Math.random() < 0.2
        }
      ],
      plotOptions
    })

    return () => {
      chart.destroy()
    }
  })
</script>

<div>
  <h4>history of the open network</h4>
  <div bind:this="{el}" />
</div>
